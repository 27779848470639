/** Header */
.ant-table-thead {
  background: #f5f5f5 !important;
  color: #4b4b4b !important;
  border-bottom: 2px solid #a2a39e !important;
}

.ant-table-thead>tr {
  background: #f5f5f5 !important;
  color: #4b4b4b !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

.ant-table-thead>tr>th {
  color: #4b4b4b !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

.ant-table-thead>tr>th.ant-table-column-sort {
  background: #f5f5f5 !important;
  color: #4b4b4b !important;
}

.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background: #f5f5f5 !important;
  color: #4b4b4b !important;
}

.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .anticon-filter,
.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .ant-table-filter-icon {
  background: #f5f5f5 !important;
  color: #4b4b4b !important;
}

.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:hover,
.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:hover {
  background: #f5f5f5 !important;
  color: #4b4b4b !important;
}

.ant-table-thead>tr>th .ant-table-header-column .ant-table-column-sorters:hover::before {
  background: #f5f5f5 !important;
}

.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-filters .anticon-filter.ant-table-filter-open,
.ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-filters .ant-table-filter-icon.ant-table-filter-open {
  background: #f5f5f5 !important;
}

.ant-table-small {
  border: none !important;
  border-radius: none !important;
}

.ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on,
.ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
  color: #4b4b4b !important;
}

/** Body */
.ant-table-thead>tr,
.ant-table-tbody>tr {
  -webkit-transition: none !important;
  transition: none !important;
}

.ant-table-row.ant-table-row-selected {
  border-left: 5px solid #a9c133 !important;
}

.ant-table-row:nth-child(even) {
  background: #f5f5f5 !important;
}

.ant-table-row:hover {
  background: none !important;
}

.ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
.ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
.ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td,
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
  background: #f5f5f5 !important;
  text-shadow: 1px 0px 0px currentColor;
}

.ant-table-filter-dropdown-link.clear {
  color: #4b4b4b !important;
}

.ant-table-filter-dropdown-link.confirm {
  color: #4b4b4b !important;
}

.ant-table-thead>tr>th .ant-table-filter-selected.anticon-filter {
  color: #4b4b4b !important;
}

/** Pagination */

.ant-pagination.mini .ant-pagination-item {
  margin: 0;
  line-height: 30px !important;
}

.ant-pagination-item {
  min-width: 26px !important;
  height: 28px !important;
  margin-right: 5px !important;
  background: #fff !important;
  border: none !important;
  color: #684e94 !important;
  border: none !important;
  font-size: 12px !important;
  font-weight: bold !important;
}

.ant-pagination-item-active {
  color: #fff !important;
  background: #684e94 !important;
  border: none !important;
  font-size: 12px !important;
  font-weight: bold !important;
}

.ant-pagination-item.ant-pagination-item-active>a {
  text-decoration: none !important;
  border: none !important;
  background: transparent !important;
  color: #fff !important;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  min-width: 26px !important;
  height: 28px !important;
  line-height: 30px !important;
  color: #fff !important;
  background: #a9c133 !important;
  border: none !important;
  font-size: 12px !important;
  font-weight: bold !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  color: #fff !important;
  background: #a9c133 !important;
  border: none !important;
  font-size: 12px !important;
  font-weight: bold !important;
}

.ant-pagination-item>a {
  font-weight: bold !important;
  color: #684e94 !important;
}

.ant-pagination-disabled a,
.ant-pagination-disabled:hover a,
.ant-pagination-disabled:focus a,
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  color: #fff !important;
  border-color: #e8e5e5 !important;
  background: #e8e5e5 !important;
  cursor: not-allowed;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #fff !important;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
a {
  color: #fff !important;
}
