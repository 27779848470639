.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  font-family: Asap !important;
  font-weight: normal !important;
  font-size: 16px !important;
  color: #4b4b4b !important;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  -webkit-transition: none !important;
  transition: none !important;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-item:hover > button,
.ant-dropdown-menu-submenu-title:hover
  > button
  .ant-dropdown-menu-item:hover
  > a,
.ant-dropdown-menu-submenu-title:hover > a {
  cursor: pointer !important;
  background-color: transparent !important;
  font-weight: bolder !important;
}

.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  display: block;
  margin: -5px -12px;
  padding: 5px 12px;
  color: #4b4b4b !important;
  text-decoration: none !important;
  -webkit-transition: none !important;
  transition: none !important;
}

.ant-dropdown-menu-item > button,
.ant-dropdown-menu-submenu-title > button {
  background: transparent !important;
  border: none !important;
  color: #4b4b4b !important;
}

.ant-dropdown-menu-item > button:hover,
.ant-dropdown-menu-submenu-title > button:hover {
  cursor: pointer !important;
}
