.ant-modal.modalLotItemReceive {
  padding-bottom: 0px !important;
  /* min-height: 360px !important; */
  width: 793px !important;
}

.ant-modal.modalLotItemReceive .ant-modal-content {
  height: 100% !important;
  min-height: 100% !important;
}

.ant-modal.modalLotItemReceive .ant-modal-body {
  padding: 0px !important;
  height: 100% !important;
  min-height: 100% !important;
}

.ant-modal.modalLotItemReceive .ant-tabs {
  height: 100% !important;
  min-height: 100% !important;
}

.ant-modal.modalLotItemReceive .ant-tabs-tabpane .ant-tabs-tabpane-active {
  height: 100% !important;
  min-height: 100% !important;
}

.ant-modal.modalLotItemReceive .ant-tabs-left-bar {
  border-right: 1px solid #a9c133 !important;
  height: 100% !important;
  min-height: 100% !important;
}

.ant-modal.modalLotItemReceive .ant-tabs-content,
.ant-modal.modalLotItemReceive .ant-tabs-left-content {
  position: relative;
  height: 100% !important;
  min-height: 100% !important;
  padding-left: 0px !important;
  border-left: 1px solid #a9c133 !important;
}
.ant-modal.modalLotItemReceive .ant-tabs-ink-bar {
  background: #684e94 !important;
  /* width: 5px !important; */
}

.ant-modal.modalLotItemReceive .ant-tabs-tab:hover {
  color: #684e94 !important;
}

.ant-modal.modalLotItemReceive .ant-tabs-nav .ant-tabs-tab-active {
  color: #684e94;
  font-weight: 500;
}

.modalLotItemReceive .ant-divider-dashed {
  background: none;
  border-top: 2px dashed #c4c4c4;
}

.modalLotItemReceive .ant-input-group-addon {
  position: relative;

  padding: 0 15px !important;
  color: #fff !important;
  text-align: center;
  background-color: #4a85ae !important;
  border: none !important;
  border-radius: 0 !important;
  border-top-right-radius: 29px !important;
  border-bottom-right-radius: 29px !important;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.modalLotItemReceive .ant-tag {
  border-radius: 15px !important;
  margin-top: 5px !important;
  background: #fff !important;
  border: 1px dashed #d8d8d8 !important;
}

.modalLotItemReceive .ant-tag .anticon-close {
  color: #d44c4c;
}
